import React from 'react';
import './IndustryWorld.css';
import z1 from "../assets/z1.jpeg"
import z2 from "../assets/z2.jpeg"
import z3 from '../assets/z3.jpeg'
import z4 from '../assets/z4.jpeg'
import z5 from '../assets/z5.jpeg'
import z6 from '../assets/z6.jpeg'
import zz1 from '../assets/zz1.jpeg'
import zz2 from '../assets/zz2.jpeg'
import zz3 from '../assets/zz3.jpeg'
import zz4 from '../assets/zz4.jpeg'
import Contact3 from './contacts/IndustryWorldContact';
import ScrollToTop from './ScrollToTop';
import ScrollToTopOnLoad from './services/ScrollTop'

const IndustryWorld = () => {

    

    
    return (
        <div className='industry-world'>
            <ScrollToTopOnLoad/>
            <div className='banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8 col-lg-6'>
                            <div className='banner-inner'>
                                <div className='heading'>
                                    <p>With SIGNAL you are first in the front line to utilize news to your business</p>
                                </div>
                                <div className='content'>
                                    <p>Get instant news for business use directly from the individually selected markets</p>
                                </div>
                                <div className='button'>
                                    <a href="#industry-world-contact">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'></div>
                    </div>
                </div>
            </div>
            <div className='section2'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-9 col-xl-6'>
                            <div className='heading'>
                                <p>Unlock Your Company’s Potential with Early Stage Industry News</p>
                            </div>
                            <div className='content'>
                                <div className='content-inner'>
                                    <h4>With SIGNAL you get:</h4>
                                    <p>Instant access to the relevant news / market events related to your unique business Notifications with the frequence you select individually, daily/weekly/monthly</p>
                                </div>
                                <div className='content-inner'>
                                    <h4>SIGNAL is flexible:</h4>
                                    <p>Select the key words, which are important for you and your business growth Select the market/country and/or region you would like to get immediate news</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section3'>
                <div className='container'>
                    <div className='heading'>
                        <p>
                            Why Early Stage News Matters:
                        </p>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={z1} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Proactive Decision-Making:</p>
                                </div>
                                <div className='content'>
                                    <p>Gain insights into upcoming projects and industry shifts before your competitors. This foresight allows you to make informed decisions, pivot strategies, and capitalize on new opportunities. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={z2} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Market Intelligence:</p>
                                </div>
                                <div className='content'>
                                    <p>Early access to news about any new ventures provides invaluable market intelligence. Understand the competitive landscape better and refine your market approach with precision. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={z3} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Strategic Planning:</p>
                                </div>
                                <div className='content'>
                                    <p>Integrate the latest industry trends into your strategic planning. Whether it’s forecasting demand, adjusting your product offerings, or entering new markets, early information empowers your company to plan effectively and mitigate risks.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={z4} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p> Innovation and R&D:</p>
                                </div>
                                <div className='content'>
                                    <p>Stay ahead in innovation by knowing the emerging trends and technological advancements in your industry. Allocate resources to R&D projects that align with future market needs and stay ahead in the innovation race.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={z5} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Networking and Partnerships:</p>
                                </div>
                                <div className='content'>
                                    <p>Identify potential partners and collaborators early on. Forge strategic alliances and build relationships that can drive your business forward before others even know the opportunity exists.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={z6} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Competitive Advantage:</p>
                                </div>
                                <div className='content'>
                                    <p>Leverage early stage news to gain a competitive advantage. Knowing what’s coming next allows you to prepare, adapt, and outperform your competitors who are reacting rather than anticipating.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section4'>
                <div className='container'>
                    <div className='heading'>
                        <p>Dive now into important insigths</p>
                    </div>
                    <div className="row">
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={zz1} alt="">
                                    </img>
                                </div>
                                <div className='heading'>
                                    <p>
                                        Create an account
                                    </p>
                                </div>
                                <div className='content'>
                                    <p>
                                        Define what information you want to receive, how often and from which media and sources.
                                        Our team helps in finding the best media in the target countries.
                                        The service can make alerts about these. The alarm is based on pre-set criteria that can be set verbally. There can be any number of criteria. For example: "Are there any large construction projects in the area?" or "What new innovations are there in hospital hygiene?".
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={zz2} alt="">
                                    </img>
                                </div>
                                <div className='heading'>
                                    <p>
                                        Receive a report
                                    </p>
                                </div>
                                <div className='content'>
                                    <p>
                                        The news that is important to you, pre-picked to your e-mail on the schedule you want.
                                        You get information about early signals about potential events, new projects or customers by analyzing a large amount of information and news flow and extracting relevant information from it.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={zz3} alt="">
                                    </img>
                                </div>
                                <div className='heading'>
                                    <p>
                                        Be the Early Bird
                                    </p>
                                </div>
                                <div className='content'>
                                    <p>
                                        Because you received information about the clients' projects at a very early stage, you secure your position in contract negotiations and the business grows quickly.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='common' id="industry-world-contact">
                <Contact3 heading="Contact Us Today" info="Reach out to us using the contact form. We are here to help with your inquiries and provide the assistance you need." />
            </div>
            <div className='section5'>
                <div className='container-fluid gx-0'>
                    <div className='row gx-0'>
                        <div className='col-md-6 order-2 order-md-1'>
                            <div className='inner'>
                                <div className='heading'>
                                    <p>Stay Ahead with Weekly Industry Insights!</p>
                                </div>
                                <div className='content'>
                                    <p>Unlock Your Company’s Potential with Early Stage Industry News.</p>
                                    <p>Get valuable, curated news about your industry delivered to your inbox every week.</p>
                                    <p>In today's fast-paced business environment, staying ahead of the curve is crucial. Imagine knowing about major industry developments before they hit the mainstream.</p>
                                    <p>With our subscription-based early stage news service, your company can gain that competitive edge.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 order-1 order-md-2'>
                            <div className='img-box'>
                                <img src={zz4} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section6'>
                <div className='container'>
                    <div className='heading'>
                        <p>FAQs</p>
                    </div>
                    <div className='inner'>
                        <div class="accordion vt-custom-accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        How is the news curated?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>Answer:</strong>Our team of experienced analysts employs a combination of advanced algorithms and expert review to curate news. We can help to source information from reputable industry publications, journals, and insider reports to ensure you receive only the most relevant and impactful news tailored to your business needs.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        What industries are covered?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>Answer:</strong> We cover a wide range of industries including technology, finance, healthcare, manufacturing, real estate, energy, and more. You can select your specific industry or multiple industries during the subscription process to ensure you receive news that is most relevant to your business.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How often will I receive updates?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>Answer:</strong> Subscribers receive a comprehensive update every Monday morning, summarizing the key developments from the past week. For those needing more frequent updates, we also offer daily briefings as part of our premium service.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Can I customize the news I receive?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>Answer:</strong> Yes, our service allows for customization. During the subscription process, you can specify your industry preferences, key topics of interest, and the frequency of updates. You can also adjust these settings at any time through your user dashboard.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        How do I manage my subscription?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>Answer:</strong> Managing your subscription is straightforward. Simply log in to your user dashboard where you can view and modify your subscription plan, update payment information, change news preferences, or cancel your subscription at any time with no hassle.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        How is my data and privacy protected?
                                    </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>Answer:</strong>We take your privacy and data security very seriously. Our platform employs industry-standard encryption and security protocols to protect your personal and company information. We are compliant with all relevant data protection regulations and our privacy policy details how your data is handled.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        What kind of support is available?
                                    </button>
                                </h2>
                                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>Answer:</strong>We offer customer support via email. Our support team is dedicated to helping you with any questions or issues you may encounter. Additionally, our comprehensive online help center includes FAQs.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                        How will this service provide a return on investment (ROI)?
                                    </button>
                                </h2>
                                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>Answer:</strong>By receiving early stage news, your company can make proactive, informed decisions, identify emerging opportunities, and mitigate risks before they impact your business. This strategic advantage translates into better market positioning, increased efficiency, and ultimately, improved profitability, offering a significant return on your investment.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </div>
    )
}

export default IndustryWorld