import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import all_country from '../services/country'
import '../css/Contact3.css'

const Contact3 = ({ heading, info }) => {
    // State to manage form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        country: '',
        message: ''
    });

    // State to manage validation errors
    const [errors, setErrors] = useState({});
    // State to manage form submission status
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState('');

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Basic validation logic
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
        // if (!formData.message) newErrors.message = 'Message is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);
            setSubmissionStatus('');

            try {
                // Replace with your API endpoint
                const response = await axios.post('https://8420-1-6-46-135.ngrok-free.app/industry-contact/', formData);

                if (response.status === 200) {
                    setSubmissionStatus('Form submitted successfully!');
                    setFormData({
                        name: '',
                        email: '',
                        company: '',
                        country: '',
                        message: ''
                    });
                    setTimeout(() => {
                        setSubmissionStatus('');
                    }, 5000);
                } else {
                    setSubmissionStatus('Failed to submit the form. Please try again.');
                }
            } catch (error) {
                if (error.response) {
                    setErrors(error.response.data)
                    setSubmissionStatus(`Error: ${error.response.status}.`);
                } else{
                    setSubmissionStatus('An error occurred. Please try again.');
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };



    return (
        <div className='contact-us3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='left-section'>
                            <form onSubmit={handleSubmit}>
                                <div className='row g-3'>
                                    <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>Name</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && <div className='error-message'>{errors.name}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>Email</label>
                                            <div className='form-field'>
                                                <input
                                                    type="email"
                                                    className='form-control'
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <div className='error-message'>{errors.email}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row g-3'>
                                            <div className='col-md-6'>
                                                <div className='form-block'>
                                                    <label>Company</label>
                                                    <div className='form-field'>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            name="company"
                                                            value={formData.company}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.company && <div className='error-message'>{errors.company}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-block'>
                                                    <label>Country</label>
                                                    <div className='form-field'>
                                                        {/* <input
                                                            type="text"
                                                            className='form-control'
                                                            name="country"
                                                            value={formData.country}
                                                            onChange={handleChange}
                                                        /> */}
                                                        <select className="form-select" aria-label="Default select example" name="country"
                                                            value={formData.country}
                                                            onChange={handleChange}
                                                            style={{ backgroundColor: '#928eb1', border:0}}
                                                            >
                                                            <option value="" disabled selected></option>
                                                            {all_country().map((country, index) => (
                                                                <option key={index} value={country}>{country}</option>
                                                            ))}
                                                        </select>

                                                        {errors.country && <div className='error-message'>{errors.country}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='form-block'>
                                                    <label>Message</label>
                                                    <div className='form-field'>
                                                        <textarea
                                                            className='form-control'
                                                            name="message"
                                                            value={formData.message}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                        {errors.message && <div className='error-message'>{errors.message}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='left-section-three'>
                                            <p>This site is protected by reCAPTCHA and the Google <Link to='https://policies.google.com/privacy'>Privacy Policy</Link> and <Link to='https://policies.google.com/terms'>Terms of Service</Link></p>
                                            <button type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? 'Submitting...' : 'SEND'}
                                            </button>
                                            {submissionStatus && <div className='text-white'>{submissionStatus}</div>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='right-section'>
                            <div className='right-section-heading'>
                                <p>{heading}</p>
                            </div>
                            <div className='right-section-info'>
                                <p>{info}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact3;
