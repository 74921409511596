import React, { useEffect } from "react";

const ScrollToTopOnLoad = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0, // Change this value to scroll to a specific position
      behavior: "smooth", // You can use "auto" for an instant scroll
    });
  }, []); // Empty dependency array ensures this runs only on component mount

  return null; // This component doesn't render anything
};

export default ScrollToTopOnLoad;
