import React from 'react';
import './WebAndMobileApps.css';
import aa4 from '../assets/aa4.jpeg';
import Contact3 from './contacts/IndustryWorldContact';
import mobileweb from "../assets/mobileweb.png"
import ScrollToTop from './ScrollToTop';
// import { Link } from 'react-router-dom';

const WebAndMobileApps = () => {
    return (
        <div className='web-mobile-apps'>
            <div className='section1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 order-2 order-md-1'>
                            <div className='card-top'>
                                <div className='vt-card'>
                                    <div className='heading'>
                                        <h1>
                                            Web and mobile applications quickly
                                        </h1>
                                    </div>
                                    <div className='content'>
                                        <p>
                                            Ready-made platform for web and mobile development speeds up the product development process by 90%! Applications launch, from your contact to markets, in 4-6 weeks! It'a also possible to integrate Ai into the business process.
                                        </p>
                                    </div>
                                    <div className='button'>
                                        <a href="#web-contact">Contact Us Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 order-1 order-md-2'>
                            <div className='img-box'>
                                <img src={mobileweb} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='heading'>
                                <h2>
                                    Make your website seamless part of your business
                                </h2>
                            </div>
                            <div className='content'>
                                <p>
                                    No more waiting or big investments - contact us and let us make your dreams come true!
                                    Start your journey to better web and mobile today
                                </p>
                                <p>
                                    Get the entire package - from design to implementation and maintenance.
                                    Normally, developing an online and mobile application requires significant time and investment, but with us it goes like a dance.v
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>
            </div>
            <div className='section3'>
                <div className='container'>
                    <div className='main-heading'>
                        <h2>
                            Fluent and easy process:
                        </h2>
                    </div>
                    <div className='outer-box'>
                        <div className='cards'>
                            <div className='headings'>
                                <div className='heading'>
                                    <h2>
                                        Step 1
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>
                                        Users point of view
                                    </h2>
                                </div>
                            </div>
                            <div className='vt-card'>
                                <div className='icon'>
                                    <img src="https://picsum.photos/200/100" alt="img" />
                                </div>
                                <div className='vt-heading'>
                                    <h2>
                                        Purpose
                                    </h2>
                                </div>
                                <div className='content'>
                                    <p>
                                        1. Define the purpose, target users, and problem the app solves.
                                    </p>
                                    <p>
                                        Create a clear use case scenario.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='cards'>
                            <div className='headings'>
                                <div className='heading'>
                                    <h2>
                                        Step 2
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>
                                        Ease of use
                                    </h2>
                                </div>
                            </div>
                            <div className='vt-card'>
                                <div className='icon'>
                                    <img src="https://picsum.photos/200/100" alt="img" />
                                </div>
                                <div className='vt-heading'>
                                    <h2>
                                        Funtion
                                    </h2>
                                </div>
                                <div className='content'>
                                    <p>
                                        2. Design the functions and appearance, including necessary features and user interface elements. Develop a wireframe to visualize the layout.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className='cards'>
                            <div className='headings'>
                                <div className='heading'>
                                    <h2>
                                        Step 3
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>
                                        Visualize it
                                    </h2>
                                </div>
                            </div>
                            <div className='vt-card'>
                                <div className='icon'>
                                    <img src="https://picsum.photos/200/100" alt="img" />
                                </div>
                                <div className='vt-heading'>
                                    <h2>
                                        Mockup
                                    </h2>
                                </div>
                                <div className='content'>
                                    <p>
                                        3. Create a prototype to test functionality and make adjustments.
                                    </p>
                                    <p>
                                        Use mockups to simulate user interactions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='cards'>
                            <div className='headings'>
                                <div className='heading'>
                                    <h2>
                                        Step 4
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>
                                        Keep it simple
                                    </h2>
                                </div>
                            </div>
                            <div className='vt-card'>
                                <div className='icon'>
                                    <img src="https://picsum.photos/200/100" alt="img" />
                                </div>
                                <div className='vt-heading'>
                                    <h2>
                                        Building
                                    </h2>
                                </div>
                                <div className='content'>
                                    <p>
                                        4. Build the app, focusing on implementing essential functionalities such as:<br />
                                        - user interface<br />
                                        - login<br />
                                        - payment procedures
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className='cards'>
                            <div className='headings'>
                                <div className='heading'>
                                    <h2>
                                        Step 5
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>
                                        Last check
                                    </h2>
                                </div>
                            </div>
                            <div className='vt-card'>
                                <div className='icon'>
                                    <img src="https://picsum.photos/200/100" alt="img" />
                                </div>
                                <div className='vt-heading'>
                                    <h2>
                                        Testing
                                    </h2>
                                </div>
                                <div className='content'>
                                    <p>
                                        5. Conduct thorough testing to ensure functionality, usability, and error-free performance. Address any issues discovered during testing.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className='cards'>
                            <div className='headings'>
                                <div className='heading'>
                                    <h2>
                                        Step 6
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>
                                        Finally there!


                                    </h2>
                                </div>
                            </div>
                            <div className='vt-card'>
                                <div className='icon'>
                                    <img src="https://picsum.photos/200/100" alt="img" />
                                </div>
                                <div className='vt-heading'>
                                    <h2>
                                        Launch
                                    </h2>
                                </div>
                                <div className='content'>
                                    <p>
                                        6. Launch the app to the public and share it with the target audience.
                                        Monitor feedback and make further improvements as needed.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className='cards'>
                            <div className='headings'>
                                <div className='heading'>
                                    <h2>
                                        Continuous improvement
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>
                                        Customers are best advisors

                                    </h2>
                                </div>
                            </div>
                            <div className='vt-card'>
                                <div className='icon'>
                                    <img src="https://picsum.photos/200/100" alt="img" />
                                </div>
                                <div className='vt-heading'>
                                    <h2>
                                        Maintenance
                                    </h2>
                                </div>
                                <div className='content'>
                                    <p>
                                        7. Get feedback from users and make continuous development.
                                    </p>
                                    <p>
                                        Seek for better usability and adding value to users.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='common' id="web-contact">
                <Contact3 heading="Reach Out Today" info="Reach out using the contact form. We are here to help with your needs and answer any questions you may have.
When your service is ready, we take care of it. We offer maintenance and hosting of your app from €100/month, all necessary services are included in the price." />
            </div>
            <div className='section4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='heading'>
                                <h2>
                                    Differentiate with Ai
                                </h2>
                            </div>
                            <div className='content'>
                                <p>
                                    Benefit from GENII-Solutions' expertise in information technology and Artificial intelligence.
                                </p>
                                <p>
                                    We can integrate artificial intelligence into your business according to your needs. For example, a bilingual chat enables a conversation with customers in their own language.
                                </p>
                                <p>
                                    Only your imagination is the limit to what you want to achieve.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='img-box'>
                                <img src={aa4} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </div>
    )
}

export default WebAndMobileApps