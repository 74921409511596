import React from 'react';
import './InvestorSearch.css';
import yy1 from '../assets/yy1.jpeg'
import yy2 from '../assets/yy2.jpeg'
import Contact3 from './contacts/IndustryWorldContact';
import ScrollToTop from './ScrollToTop';
import ScrollToTopOnLoad from './services/ScrollTop'

const InvestorSearch = () => {
    return (
        <div className='investor-search'>
            <ScrollToTopOnLoad/>
            <div className='section1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='heading'>
                                <h1>
                                    Investor Search Engine
                                </h1>
                            </div>
                            <div className='content'>
                                <p>
                                    Equity investors, Venture capitalists, Debt lenders or other financiers available just in seconds. Get the initial contact to the Investors and capital faster and cheaper than ever.
                                </p>
                            </div>
                            <div className='button'>
                                <a href="#investor-search-contact">Get Funding Here</a>
                            </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>
            </div>
            <div className='section2'>
                <div className='container-fluid gx-0'>
                    <div className='row gx-0'>
                        <div className='col-md-6'>
                            <div className='card1'>
                                <div className='heading'>
                                    <h2>
                                        Get in minutes
                                    </h2>
                                </div>
                                <div className='content'>
                                    <p>
                                        List of Investors with Investment Philosophy, which fits to your capital need
                                    </p>
                                    <p>
                                        Instant initial round of contacts to start your negotiation process
                                    </p>
                                    <h3>
                                        Investor–Search-Engine is flexible
                                    </h3>
                                    <p>
                                        You select what kind (size, round, type, time etc.) of investment you would like to have
                                    </p>
                                    <p>
                                        You select from which geographical region you would like to have investors
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='img-box'>
                                <img src={yy1} alt=""></img>
                            </div>
                        </div>
                        <div className='col-md-6 order-2 order-md-1'>
                            <div className='img-box'>
                                <img src={yy2} alt=""></img>
                            </div>
                        </div>
                        <div className='col-md-6 order-1 order-md-2'>
                            <div className='card2-main'>
                                <div className='card2'>
                                    <div className='heading'>
                                        <h2>Additional services by request</h2>
                                    </div>
                                    <div className='content'>
                                        <p>Our investment specialists can support you in the whole process, from presentation to negotiations. All you need is to contact us.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='common' id="investor-search-contact">
                <Contact3 heading="Contact our expert" info="Let's make an appointment and see how quickly and easily you can find funding to your investment needs." />
            </div>
            <ScrollToTop />
        </div>
    )
}

export default InvestorSearch