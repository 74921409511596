import React from 'react'
import './PartnerSearch.css';
// import banner1 from '../assets/partner-search-banner1.jpeg';
import a1 from '../assets/a1.jpeg';
import a2 from '../assets/a2.jpeg';
import a3 from '../assets/a3.jpeg';
import a4 from '../assets/a4.jpeg';
import a5 from '../assets/a5.jpeg';
import a6 from '../assets/a6.jpeg';
import b1 from '../assets/b1.jpeg';
import b2 from '../assets/b2.jpeg';
import b3 from '../assets/b3.jpeg';
import b4 from '../assets/b4.jpeg';
import banner3 from '../assets/partner-search-banner3.jpeg';
import PartnerContact from './contacts/PartnerContact';
import ScrollToTop from './ScrollToTop';
import ScrollToTopOnLoad from './services/ScrollTop'

const PartnerSearch = () => {
    return (
        <div className='partner-search'>
             <ScrollToTopOnLoad/>
            <div className='partner-search-banner'>
                {/* <div className='img-box1'>
                    <img src={banner1} alt=""></img>
                </div> */}
                <div className='img-box2'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-8 col-xl-6'>
                                <div className='img-box2-inner'>
                                    <div className='heading'>
                                        <p>Easiest, fastest and most cost-effective way to global markets</p>
                                    </div>
                                    <div className='content'>
                                        <p>Connects products and services with any foreign markets in minutes, all the needed information is in hands. GENII-AI finds the best way to go to markets and most suitable partners.</p>
                                    </div>
                                    <div className='button'>
                                        <a href="#partner-search-contact">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='partner-search-section2'>
                <div className='container'>
                    <div className='heading'>
                        <p>Unique artificial intelligence application</p>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='partner-search-section2-card'>
                                <div className='img-box'>
                                    <img src={a1} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Easy</p>
                                </div>
                                <div className='content'>
                                    <p>Only decision to do is what to export and where, or where to expand markets</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='partner-search-section2-card'>
                                <div className='img-box'>
                                    <img src={a2} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Fast</p>
                                </div>
                                <div className='content'>
                                    <p>Application combines and simplifies data and gives clear language answers to export decision questions almost in real time!</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='partner-search-section2-card'>
                                <div className='img-box'>
                                    <img src={a3} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Cost effective</p>
                                </div>
                                <div className='content'>
                                    <p>Dramatically better than the current way of operating. The implementation of export projects is cheaper and faster compared to expert and/or consulting assignments, which are slow, expensive and non-scalable services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='partner-search-section2-card'>
                                <div className='img-box'>
                                    <img src={a4} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>No limitations</p>
                                </div>
                                <div className='content'>
                                    <p>Works regardless of the company's size, industry, products or services, or at what stage and in which geographical area your business is, or would like to be.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='partner-search-section2-card'>
                                <div className='img-box'>
                                    <img src={a5} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Reliable</p>
                                </div>
                                <div className='content'>
                                    <p>Export readiness analysis helps to assess the appropriate time to start an export project.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='partner-search-section2-card'>
                                <div className='img-box'>
                                    <img src={a6} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <p>Continuous growth</p>
                                </div>
                                <div className='content'>
                                    <p>Learning AI is a strategic tool, the use will also benefit all future export projects</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='partner-search-section3'>
                <div className='container-fluid gx-0'>
                    <div className='row gx-0'>
                        <div className='col-md-6'>
                            <div className='img-box'>
                                <img src={banner3} alt=""></img>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='right-section'>
                                <div className='heading'>
                                    <p>We know Export</p>
                                </div>
                                <div className='content'>
                                    <p>We have been researching the use of artificial intelligence in the processing of large amounts of data for years with excellent results. We have multiple pilot cases that could grow their business just in weeks.</p>
                                </div>
                                <div className='ordered-list'>
                                    <ol>
                                        <li>
                                            <strong>Management problem in real time:</strong> What information is needed to make good decisions? How to get the information in a structured way? What to decide - and based on what?
                                        </li>
                                        <li>
                                            <strong>Technology problem in real time:</strong> Systems are not connected, information is splittered in different user Iocation servers and takes time to analyze. Technology givies silo based information for efficient decision making – but how do we compare.
                                        </li>
                                        <li>
                                            <strong>Real time problem as itself:</strong> Real time is only real time when looked. Real time connection but the systems does not exist without extensive integration! Systems will bring only the info what it is meant to bring.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='common' id='partner-search-contact'>
                <PartnerContact />
            </div>
            <div className='partner-search-section4'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-9'>
                            <div className='heading'>
                                <p>GENII-AI</p>
                            </div>
                            <div className='content'>
                                <p>Collects and verifies, i.e. ensures the correctness of the data.</p>
                                <p>Combines and simplifies it so that the artificial intelligence, LLM, forms clear language answers for the user.</p>
                                <p>Everything happens based on live data and in minutes.</p>
                                <p>The service has been developed<strong>in cooperation with Lappeenranta-Lahti University of Technology and LAB University of Applied Sciences, Southeast Finland ELY Center and Business Finland</strong>.
                                </p>
                                <p>Services are developed to help as many companies as possible to grow both in Finland and abroad.</p>
                                <p>The team has decades of experience in international trade, business development and the use of information technology in decision-making.</p>
                                <p>Reach export targets ten, even hundred times more effectively.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='partner-search-section5'>
                <div className='container'>
                    <div className='partner-search-section5-heading'>
                        <p>Export Decision Making Process</p>
                    </div>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='partner-search-section5-card'>
                                <div className='img-box'>
                                    <img src={b1} alt="">
                                    </img>
                                </div>
                                <div className='heading'>
                                    <p>Are you competitive in exprt</p>
                                </div>
                                <div className='content'>
                                    <p>In order to be competitive in Export, your “House keeping” need to be similarly competitive in international business environment. Test your readiness with scientifically and practically tested method from thousands of companies and organisations with GENII Guarantee.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='partner-search-section5-card'>
                                <div className='img-box'>
                                    <img src={b2} alt="">
                                    </img>
                                </div>
                                <div className='heading'>
                                    <p>Find right Partners to Export</p>
                                </div>
                                <div className='content'>
                                    <p>Normally, companies want to grow AND – unfortunately - the growth is mandatory in current international competition. In case you want to have structured, well know process to execute your planning process with your stakeholders and company management, who normally are the responsible person for planning, contact us. GENII have a tested method to execute the planning in a PRO-way – international growth, test the markets, right markets. Analyses can be done based on your own data. Entry options and Sales Channel Development tools available.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='partner-search-section5-card'>
                                <div className='img-box'>
                                    <img src={b3} alt="">
                                    </img>
                                </div>
                                <div className='heading'>
                                    <p>Get direct contacts with right Partners to Export</p>
                                </div>
                                <div className='content'>
                                    <p>What is your model to entry and penetrate new markets – and more importantly, do you know the differences in each and every country concerning legislation and mandatory business processes? GENII can get the answers in minutes “mining” the data from each and every country. Partner search with initial contacts only with initiating your interests to the countries you are interested - instead of hiring local consultants. There are different modern way for - Supplier evaluations, Partner Contacts, Direct Sales Opportunities and/or Company Establishment.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='partner-search-section5-card'>
                                <div className='img-box'>
                                    <img src={b4} alt="">
                                    </img>
                                </div>
                                <div className='heading'>
                                    <p>Expand you Export with right Partners and organic growth -  and/or M&As</p>
                                </div>
                                <div className='content'>
                                    <p>Whether you are happy with your current market position or want to expand your business further, we have tested methods and tools to make your business successful. Sales Accelerator, Market Monitor, Acquisition Candidate Search or even Investor Search Engine, in order to find Investor for your operation – are with You with immediate access. Just utilise our GENII services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </div>
    )
}

export default PartnerSearch