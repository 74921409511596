// export default Contact2
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Contact2.css';

const PartnerContact = () => {
    // State to manage form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        addressLine1: '',
        city: '',
        zipCode: '',
        country: '',
        phone: '',
        message: ''
    });

    // State to manage validation errors
    const [errors, setErrors] = useState({});
    // State to manage form submission status
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState('');

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Basic validation logic
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';      
        if (!formData.phone) newErrors.phone = 'Phone is required';
     

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);
            setSubmissionStatus('');

            try {
                // Replace with your API endpoint
                const response = await fetch('https://8420-1-6-46-135.ngrok-free.app/send-mail/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    setSubmissionStatus('Form submitted successfully!');
                    setFormData({
                        name: '',
                        email: '',
                        company: '',
                        addressLine1: '',
                        city: '',
                        zipCode: '',
                        country: '',
                        phone: '',
                        message: ''
                    });
                    setTimeout(() => {
                        setSubmissionStatus('');
                    }, 5000);
                } else {
                    setSubmissionStatus('Failed to submit the form. Please try again.');
                }
            } catch (error) {
                if (error.response) {
                    setErrors(error.response.data)
                    setSubmissionStatus(`Error: ${error.response.status}.`);
                } else{
                    setSubmissionStatus('An error occurred. Please try again.');
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <div className='contact-us2'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='left-section'>
                            <form onSubmit={handleSubmit}>
                                <div className='row g-3'>
                                    <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>Name</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && <div className='error-message'>{errors.name}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>Email</label>
                                            <div className='form-field'>
                                                <input
                                                    type="email"
                                                    className='form-control'
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <div className='error-message'>{errors.email}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>Company</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="company"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                />
                                                {errors.company && <div className='error-message'>{errors.company}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 pt-4'>
                                        <h4 className='form-heading-title mb-0'>Address</h4>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-block'>
                                            <label>Line 1</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="addressLine1"
                                                    value={formData.addressLine1}
                                                    onChange={handleChange}
                                                />
                                                {errors.addressLine1 && <div className='error-message'>{errors.addressLine1}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-12'>
                                        <div className='form-block'>
                                            <label>Line 2</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="addressLine2"
                                                    value={formData.addressLine2}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>City</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                />
                                                {errors.city && <div className='error-message'>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>State</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="state"
                                                    value={formData.state}
                                                    onChange={handleChange}
                                                />
                                                {errors.state && <div className='error-message'>{errors.state}</div>}
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>ZIP code</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="zipCode"
                                                    value={formData.zipCode}
                                                    onChange={handleChange}
                                                />
                                                {errors.zipCode && <div className='error-message'>{errors.zipCode}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-block'>
                                            <label>Country</label>
                                            <div className='form-field'>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="country"
                                                    value={formData.country}
                                                    onChange={handleChange}
                                                />
                                                {errors.country && <div className='error-message'>{errors.country}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row g-3 mt-4'>
                                            <div className='col-md-12'>
                                                <div className='form-block'>
                                                    <label>Phone</label>
                                                    <div className='form-field'>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            name="phone"
                                                            value={formData.phone}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.phone && <div className='error-message'>{errors.phone}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='form-block'>
                                                    <label>Message</label>
                                                    <div className='form-field'>
                                                        <textarea
                                                            className='form-control'
                                                            name="message"
                                                            value={formData.message}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                        {errors.message && <div className='error-message'>{errors.message}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='left-section-three'>
                                            <p>This site is protected by reCAPTCHA and the Google <Link to='https://policies.google.com/privacy'>Privacy Policy</Link> and <Link to='https://policies.google.com/terms'>Terms of Service</Link></p>
                                            <button type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? 'Submitting...' : 'SEND'}
                                            </button>
                                            {submissionStatus && <div className='submission-status text-white'>{submissionStatus}</div>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='right-section'>
                            <div className='right-section-heading'>
                                <p>Contact our expert</p>
                            </div>
                            <div className='right-section-info'>
                                <p>What is your export stage?</p>
                                <p>Let's make an appointment and see how quickly and easily you can bring the quality of decision-making to the top of the world!</p>
                                <ul>
                                    <li>Export Readiness Analysis</li>
                                    <li>Planning the Export</li>
                                    <li>Planning the Market Entry</li>
                                    <li>Executing the Market Entry</li>
                                    <li>Expanding the Market Position</li>
                                </ul>
                                <p>GENII combines decisions and tools related to export business, solutions are obtained in minutes. Clear language answers are based on live information. Reach your export targets ten even hundred times more effectively.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerContact;
