// src/router.js
import { createBrowserRouter } from 'react-router-dom';
import AiSearch from './components/AiSearch';
import Layout from './components/Layout';
import PartnerSearch from './components/PartnerSearch';
import IndustryWorld from './components/IndustryWorld';
import InvestorSearch from './components/InvestorSearch';
import SellMore from './components/SellMore';
import WebAndMobileApps from './components/WebAndMobileApps';
import HiddenPage from './components/HiddenPage';
import PageNotFound from './components/PageNotFound';


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout><AiSearch /></Layout>
    },
    {
        path: "/partner-search",
        element: <Layout><PartnerSearch /></Layout>
    },
    {
        path: "/industry-world",
        element: <Layout><IndustryWorld /></Layout>
    },
    {
        path: "/investor-search",
        element: <Layout><InvestorSearch /></Layout>
    },
    {
        path: "/sell-more",
        element: <Layout><SellMore /></Layout>
    },
    {
        path: "/web-and-mobile-apps",
        element: <Layout><WebAndMobileApps /></Layout>
    },
    {
        path: "/ZHVtbXl0ZXh0OjEyMzQ1Njc4OTA6c2FtcGxlZGF0YQ==",
        element: <Layout><HiddenPage /></Layout>
    },
    {
        path : '*',
        element : <PageNotFound/>
    }

]);

export default router;
