import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='footer-inner'>
                    <div className='footer-one'>
                        <p>GENII-Solutions, AI assisted management tools.</p>
                    </div>
                    <div className='footer-two'>
                        <Link to='/'>AI Solutions Home</Link>
                        <Link to='/partner-search'>Partner Search</Link>
                        <Link to='/industry-world'>Industry World News</Link>
                        <Link to='/investor-search'>Investor Search</Link>
                        <Link to='/sell-more'>Sell More</Link>
                        <Link to='/web-and-mobile-apps'>Web & Mobile Applications</Link>
                    </div>
                    {/* <div className='footer-three'>
                        <div className='social-icons'>
                            <Link to=""><i className="fa-brands fa-linkedin"></i></Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </div >
    )
}

export default Footer