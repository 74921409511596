import React, { useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Header.css';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const offcanvasRef = useRef(null);

    const handleLinkClick = (path) => {
        // Ensure `bootstrap` is available
        if (window.bootstrap && offcanvasRef.current) {
            const offcanvasElement = window.bootstrap.Offcanvas.getOrCreateInstance(offcanvasRef.current);
            offcanvasElement.hide();
        }

        // Navigate to the new path
        navigate(path);
    };

    return (
        <div className='header'>
            <nav className="navbar bg-body-tertiary">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" ref={offcanvasRef}>
                        <div className="offcanvas-header">
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}
                                        to='/'
                                        onClick={() => handleLinkClick('/')}
                                    >
                                        Ai Solutions Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${location.pathname === '/partner-search' ? 'active' : ''}`}
                                        to='/partner-search'
                                        onClick={() => handleLinkClick('/partner-search')}
                                    >
                                        Partner Search
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${location.pathname === '/industry-world' ? 'active' : ''}`}
                                        to='/industry-world'
                                        onClick={() => handleLinkClick('/industry-world')}
                                    >
                                        Industry World News
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${location.pathname === '/investor-search' ? 'active' : ''}`}
                                        to='/investor-search'
                                        onClick={() => handleLinkClick('/investor-search')}
                                    >
                                        Investor Search
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${location.pathname === '/sell-more' ? 'active' : ''}`}
                                        to='/sell-more'
                                        onClick={() => handleLinkClick('/sell-more')}
                                    >
                                        Sell More
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${location.pathname === '/web-and-mobile-apps' ? 'active' : ''}`}
                                        to='/web-and-mobile-apps'
                                        onClick={() => handleLinkClick('/web-and-mobile-apps')}
                                    >
                                        Web & Mobile Applications
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Link class="navbar-brand complany-logo" to='/'>
                        <img src={logo} alt="genii_solutions"></img>
                    </Link>

                    <a href='https://signal.genii-solutions.com/auth/login' target='_blank' className="btn btn-primary me-2" rel="noopener">
                        Login
                    </a>
                    <a href='https://signal.genii-solutions.com/auth/register' className="btn btn-primary" target='_blank' rel="noopener">
                        Register
                    </a>

                </div>
            </nav>
        </div>
    );
};

export default Header;
