import React from "react";
import error from '../assets/error.png'

const PageNotFound = () => {

  return <>
  
  <section class="hero-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="payment-wrap-blk">                        
                        <div class="payment-content-blk">                        
                            <h1>Error<span>404</span></h1>
                            <p>Hi, This standard HTTP error message code signals the website you were trying to reach couldn't be found on the server.</p>
                            <div class="payment-page-btn-blk">
                                <a href="/">Go to homepage</a>
                            </div>
                        </div>
                        <div class="payment-img-blk">
                            <img src={error} alt="page not found" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>
};

export default PageNotFound;
