import React from 'react';
import './SellMore.css';
import aa1 from '../assets/aa1.jpeg'
import aa2 from '../assets/aa2.jpeg'
import aa3 from '../assets/aa3.jpeg'
import Contact3 from './contacts/IndustryWorldContact';
import ScrollToTop from './ScrollToTop';

const SellMore = () => {
    return (
        <div className='sell-more'>
            <div className='banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-10 col-xl-6'>
                            <div className='inner'>
                                <div className='heading'>
                                    <h2>BOOST B2B sales fast!</h2>
                                </div>
                                <div className='content'>
                                    <p>All sales development methods have already been invented in history. With the help of AI, these methods and your company are combined.</p>
                                </div>
                                <div className='button'>
                                    <a href="#sell-more-contact">Sell More Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-10 col-xl-6'>
                            <div className='heading'>
                                <h2>
                                    Choose how to increase sales
                                </h2>
                            </div>
                            <div className='content'>
                                <ol>
                                    <li>
                                        <h2> Sell more to excisting customers</h2>
                                        <p>The easiest way, just take care of customers' needs.</p>
                                    </li>
                                    <li>
                                        <h2>
                                            Find new customers
                                        </h2>
                                        <p>Lead generation, competitiveness factors and marketing.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='common' id="sell-more-contact">
                <Contact3 heading="Yes, I want to sell more now!" info="If you want to grow your sales, contact us immediately." />
            </div>
            <div className='section3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='heading'>
                                <h2>
                                    3 steps to increase sales fast
                                </h2>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={aa1} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <h2>
                                        Step 1
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>COMPANY'S INTERNAL FACTORS</h2>
                                </div>
                                <div className='content'>
                                    <p>Products and sales processes. Define what products and services the company makes and how it sells them.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={aa2} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <h2>
                                        Step 2
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>CUSTOMERS' NEEDS</h2>
                                </div>
                                <div className='content'>
                                    <p>Why and how they buy. Define who the company's customers are, where they are and how to reach them</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='vt-card'>
                                <div className='img-box'>
                                    <img src={aa3} alt=""></img>
                                </div>
                                <div className='heading'>
                                    <h2>
                                        Step 3
                                    </h2>
                                </div>
                                <div className='sub-heading'>
                                    <h2>MAKE IT HAPPEN</h2>
                                </div>
                                <div className='content'>
                                    <p>AI-Assisted analyzes to define clear instructions. The actions that can be used to get customers to buy are defined.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </div>
    )
}

export default SellMore