import React from 'react'
import './AiSearch.css';
import s1 from '../assets/s1.jpg';
import s22 from '../assets/s22.png';
import s3 from '../assets/s3.jpg';
import r1 from '../assets/r1.jpeg';
import r2 from '../assets/r2.jpeg';
import r3 from '../assets/r3.jpeg';
import r4 from '../assets/r4.jpeg';
import r5 from '../assets/r5.jpeg';
import d1 from "../assets/d1.jpeg";
import d2 from '../assets/d2.jpeg';
import d3 from '../assets/d3.jpeg';
import ff from '../assets/ff.png';
import p1 from '../assets/p1.jpeg';
import p2 from '../assets/p2.jpeg'
import HomeContact from './contacts/HomeContact';
import { Link } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

const AiSearch = () => {
    return (
        <>

            <div className='aiSearch-main'>

                <div className='banner'>
                    <div className='banner-inner'>
                        <h1>Are you looking for new business, a partner or investors from aboard</h1>
                        <p>Unique AI application produces real-time, plain-language and usable analyzes and answers questions to support decision-making.</p>
                        <a className='learn-more' href="#aisearch-contact">Learn More</a>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='section2-inner'>
                                    <h2>AI-Assisted management solutions in no time</h2>
                                    <p>Get answers to complicated and time consuming business decisions based in real-time information in minutes.</p>
                                    <p>GENII Ai combines data and management decisions, creates analyzes and instructions in real time. It's easy to use and gives immediate benefits.</p>
                                    <p>
                                        We understand management and business development processes as well as the possibilities of IT and AI.
                                    </p>
                                    <p>
                                        Our solutions cover all aspects of the value chain, from managing suppliers through logistics and internal operations to sales and customer service.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'></div>
                        </div>

                    </div>
                </div>
                <div className='section3'>
                    <div className='container'>
                        <div className='section3-heading'>
                            <h2>Future is filled with AI solutions</h2>
                        </div>
                        <div className='section3-inner'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='section3-img_box'>
                                        <img src={s1} alt=""></img>
                                    </div>
                                    <div className='section3-inner_heading'>
                                        <p>Partner network</p>
                                    </div>
                                    <div className='section3-inner_info'>
                                        <p>We work in cooperation with Lappeenranta-Lahti University of Technology and LAB University of Applied Sciences, Ministry of Economic Affairs and Employment of Finland, Southeast Finland ELY Center and Business Finland.</p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='section3-img_box'>
                                        <img src={s22} alt=""></img>
                                    </div>
                                    <div className='section3-inner_heading'>
                                        <p>Artificial intelligence expertise</p>
                                    </div>
                                    <div className='section3-inner_info'>
                                        <p>Our extensive, international network understands the possibilities of artificial intelligence and we can quickly bring the latest innovations to the benefit of our customers' businesses.</p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='section3-img_box'>
                                        <img src={s3} alt=""></img>
                                    </div>
                                    <div className='section3-inner_heading'>
                                        <p>Information security expertise</p>
                                    </div>
                                    <div className='section3-inner_info'>
                                        <p>We guarantee our customers bank-level information security. We use state-of-the-art technology and adhere to the strictest global security standards in the industry to ensure complete confidentiality and security.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section4'>
                    <div className='container'>
                        <div className='row gy-5'>
                            <div className='col-md-6'>
                                <div className='section4-inner'>
                                    <div className='section4-inner-heading'>
                                        <p className='main-heading'>GENII Solutions</p>
                                    </div>
                                    <div className='section4-inner-heading2'>
                                        <p>Partner Search</p>
                                    </div>
                                    <div className='section4-inner-info'>
                                        <p>We make export decisions effortless by grouping and automating the use of data sources. Artificial intelligence makes clear answers based on this information. Export planning, starting in different markets and market expansion is done based on live data in minutes.</p>
                                    </div>
                                    <div className='section4-inner-button'>
                                        <Link to='/partner-search'>Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='section4-img_box'>
                                    <img src={r1} alt=""></img>
                                </div>
                            </div>
                        </div>
                        <div className='row gy-5'>
                            <div className='col-md-6 order-2 order-md-1'>
                                <div className='section4-img_box'>
                                    <img src={r2} alt=""></img>
                                </div>
                            </div>
                            <div className='col-md-6 order-1 order-md-2'>
                                <div className='section4-inner'>
                                    <div className='section4-inner-heading'>
                                        <p>Industry World News</p>
                                    </div>
                                    {/* <div className='section4-inner-heading2'>

                                    </div> */}
                                    <div className='section4-inner-info'>
                                        <p>Instant access to the relevant news / market events related to your unique business. Notifications with the frequence you select.</p>
                                        <p>SIGNAL is Flexible:</p>
                                        <ol>
                                            <li>Select the key words, which are important for you and your business growth. </li>
                                            <li>Select the market/country and/or region you would like to get immediate news.</li>
                                        </ol>
                                    </div>
                                    <div className='section4-inner-button'>
                                        <Link to='/industry-world'>Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row gy-5'>
                            <div className='col-md-6'>
                                <div className='section4-inner'>
                                    <div className='section4-inner-heading'>
                                        <p>Investor Search-Engine</p>
                                    </div>
                                    {/* <div className='section4-inner-heading2'>
                                    <p>Export Decision Making Process</p>
                                </div> */}
                                    <div className='section4-inner-info'>
                                        <p>Equity Investor, Venture Capitalists, Debt Lenders or other financers available just in seconds. Get the contact to the Investors and capital faster and cheaper than ever. </p>
                                    </div>
                                    <div className='section4-inner-button'>
                                        <Link to='/investor-search'>Get Funding Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='section4-img_box'>
                                    <img src={r3} alt=""></img>
                                </div>
                            </div>
                        </div>
                        <div className='row gy-5'>
                            <div className='col-md-6 order-2 order-md-1'>
                                <div className='section4-img_box'>
                                    <img src={r4} alt=""></img>
                                </div>
                            </div>
                            <div className='col-md-6 order-1 order-md-2'>
                                <div className='section4-inner'>
                                    <div className='section4-inner-heading'>
                                        <p>Sell more</p>
                                    </div>
                                    {/* <div className='section4-inner-heading2'>
                                    <p>Export Decision Making Process</p>
                                </div> */}
                                    <div className='section4-inner-info'>
                                        <p>In the history, all methods of sales development have been invented, with the help of Ai, these methods and your company are combined.<br />Boost targets to extreme growth, now the process is available for all the companies!</p>
                                    </div>
                                    <div className='section4-inner-button'>
                                        <Link to='/sell-more'>Sell More Fast</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row gy-5'>
                            <div className='col-md-6'>
                                <div className='section4-inner'>
                                    <div className='section4-inner-heading'>
                                        <p>Web and mobile applications</p>
                                    </div>
                                    {/* <div className='section4-inner-heading2'>
                                    <p>Export Decision Making Process</p>
                                </div> */}
                                    <div className='section4-inner-info'>
                                        <p>Ready-made platform for web and mobile development speeds up the product development process by 90%! Applications launch, from your contact to markets, in 4-6 weeks! <br />It'a also possible to integrate artificial intelligence into the business process.</p>
                                    </div>
                                    <div className='section4-inner-button'>
                                        <Link to="web-and-mobile-apps">Start Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='section4-img_box'>
                                    <img src={r5} alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section5'>
                    <div className='container'>
                        <div className='section5-heading'>
                            <p>World-class team at your service</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='section-5-img_box'>
                                    <img src={d1} alt=""></img>
                                </div>
                                <div className='section-5-inner_heading'>
                                    <p>Ari Johansson, founding partner</p>
                                </div>
                                <div className='secion-5-inner_info'>
                                    <p>Experienced business developer in various industries in Finland and in an international environment.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='section-5-img_box'>
                                    <img src={d2} alt=""></img>
                                </div>
                                <div className='section-5-inner_heading'>
                                    <p>Oskar Rautiainen, Law and Finance, founding partner</p>
                                </div>
                                <div className='secion-5-inner_info'>
                                    <p>Expert in International Business Law, International Trade and Finance, and International Business Development</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='section-5-img_box'>
                                    <img src={d3} alt=""></img>
                                </div>
                                <div className='section-5-inner_heading'>
                                    <p>Antti Johansson, Branding, marketing, founding partner</p>
                                </div>
                                <div className='secion-5-inner_info'>
                                    <p>Brand builder and a combination of visionary and realist. The educational background combines business analytics, international business and entrepreneurship.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='common' id="aisearch-contact">
                    <HomeContact />
                </div>
                <div className='section6'>
                    <div className='container-fluid gx-0'>
                        <div className='row gx-0'>
                            <div className='col-md-6'>
                                <div className='section6-img_box'>
                                    <img src={ff} alt=""></img>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='section6-inner'>
                                    <div className='section6-inner_heading'>
                                        <p>The decision-making model of the future at your disposal</p>
                                    </div>
                                    <div className='section6-inner_info'>
                                        <p>GENII-Solution is the missing link between information systems and people. We use the latest artificial intelligence models. Safe, all information is processed in your own cloud. The more you use GENII, the better the answers you get.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section7'>
                    <div className='container'>
                        <div className='section7-heading'>
                            <p>Conventional vs. AI-assisted decision-making</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='section7-inner-img_box'>
                                    <img src={p1} alt=""></img>
                                </div>
                                <div className='section7-inner-heading'>
                                    <p>Conventional decision-making</p>
                                </div>
                                <div className='section7-inner-info'>
                                    <p>MANY MANAGEMENT LAYERS�DATA GATHERING-INTERNAL MEETINGS- DECISIONS</p>
                                    <p>Decisions are made on the basis of uncertain information: possible dependencies and consequences are laborious, slow and often impossible to assess with certainty.</p>
                                </div>
                            </div>


                            <div className='col-md-6'>
                                <div className='section7-inner-img_box'>
                                    <img src={p2} alt=""></img>
                                </div>
                                <div className='section7-inner-heading'>
                                    <p>AI-assisted decision-making</p>
                                </div>
                                <div className='section7-inner-info'>
                                    <p>AUTOMATED DATA COLLECTION-DEFINED PARAMETERS-DECISIONS</p>
                                    <p>All the needed data is collected for the decision, defined decision making processes give unvarnished analysies of the current situation and give action proposals based on the analysis and describes scenarios caused by the decisions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section8'>
                    <div className='container-fluid gx-0'>
                        <div className='banner2'>
                            <div className='banner2-outer'>
                                <div className='container'>
                                    <div className='row justify-content-center'>
                                        <div className='col-md-6'>
                                            <div className='banner2-inner'>
                                                <div className='banner2-inner-heading'>
                                                    <p>Be part of the development</p>
                                                </div>
                                                <div className='banner2-inner-info'>
                                                    <p>We are developing artificial intelligence-assisted decision-making processes. We develop new services to facilitate company processes. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section9'>
                    <div className='container'>
                        <div className='section9-heading'>
                            <p>FAQ</p>
                        </div>
                        <div className='section9-inner'>
                            <div class="accordion vt-custom-accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What is Ai-Assisted Decision Making?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Answer:</strong> Decision are based on large amount of diverse data from multiple sources that is harmonised into accessible form. By understanding management processes we can make questions that answer significant and important questions of the management.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How to use GENII?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Answer:</strong> Implementation is easy when we have gone through your needs.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            What kind of support is available?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Answer:</strong>Our support team assists you at first possible time. You can reach out via email, chat, or phone.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            How is data security ensured?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Answer:</strong> We prioritize data security. Our SaaS service employs encryption protocols, regular security audits, and follows industry best practices to safeguard your data.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            What happens if there's a service outage or downtime?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Answer:</strong>We have a robust infrastructure to minimize downtime. In the rare event of an outage, our team works swiftly to resolve issues. We also provide status updates and communication during such incidents.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            How often are updates and new features released?
                                        </button>
                                    </h2>
                                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Answer:</strong>We regularly update our service to enhance functionality and introduce new features. Updates are usually rolled out seamlessly, and we provide release notes to keep you informed about the latest improvements.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
        </>
    )
}

export default AiSearch