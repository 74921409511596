import React from 'react';
import './HiddenPage.css';
import cc1 from '../assets/cc1.jpeg';
import HiddenContact from './contacts/HiddenContact';

const HiddenPage = () => {
    return (
        <div className='hidden-page'>
            <div className='section1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-10 col-xl-6'>
                            <div className='heading'>
                                <h1>
                                    Viennin aloittaminen tai markkinoiden kasvattaminen AI:n avustamana on helppoa, edullista ja turvallista
                                </h1>
                            </div>
                            <div className='content'>
                                <p>
                                    Teemme vientipäätösten tekemisestä vaivatonta, ryhmittämällä ja automatisoimalla tietolähteiden käyttöä. GENII-Solutions-kokonaisvaltainen työkalupaletti koko vientiprosessiin sopii kaiken kokoisille yrityksille huolimatta teollisuudenalasta ja maantieteellisestä suuntautumisesta tai yrityksen vientiliiketoiminnan vaiheesta.
                                </p>
                            </div>
                            <div className='button'>
                                <button>
                                    Varaa tästä aika 30 minuutin keskusteluun asiantuntijamme kanssa
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='content'>
                                <p>
                                    Palvelu on kehitetty yhteistyössä LUT-yliopiston ja LAB-ammattikorkeakoulun, Kaakkois-Suomen ELY-keskuksen sekä Business Finlandin kanssa. Autamme myös rahoituksen hankkimisessa.
                                </p>
                                <p>
                                    Teköäly muodostaa selkokieliset vastaukset kaikkiin kysymyksiin kohdemaan reaaliaikaiseen tietoon perustuen. Kaikki tapahtuu vain minuuteissa murto-osalla nykyisistä kohdemaan markkinatietojen, kumppanien ja muiden tietojen hankkimisen kustannuksista.
                                </p>
                                <h2>
                                    Industry World News
                                </h2>
                                <p>
                                    Tuo toimialanne tärkeimmät uutiset halutulta alueelta suodatettuna sähköpostiinne haluamallanne rytmillä. Saatte valtavan kilpailuedun kun voitte hyödyntää toimialanne tapahtumat ensimmäisenä ja pääsette tarjoamaan palvelujanne mahdollisimman aikaisessa vaiheessa.
                                </p>
                                <h2>
                                    GENII-Partner Search
                                </h2>
                                <p>
                                    Vientiliiketoiminnan tärkein työkalu, valitse kohdemaa ja teköäly muodostaa selkokieliset vastaukset tärkeisiin kysymyksiin kohdemaan reaaliaikaiseen tietoon perustuen vain minuuteissa ja murto-osalla nykyisistä vientipäätösten kustannuksista. Vastaukset perustuvat verifioituun ja ajankohtaiseen tietoon. Partner Search auttaa kaikkia toimialoja kaikissa kohdemaissa. Dataa voi halutessaan ohjata käsittelemään yksityiskohtaista tietoa, markkinoista, kilpailutilanteesta, kumppanivalinnoista, toimijoista markkinoilla, markkinapenetraation malleista (agentit, oma yritys, myyntikonttori), eli juuri siitä, mitä yrityksen avainhenkilöt joutuvat miettimään ennen investointeja vientiliiketoiminnan ja/tai liiketoiminnan kehittämiseksi.
                                </p>
                                <h2>
                                    Vientiliiketoiminnan yrityspäätökset
                                </h2>
                                <p>
                                    Menestyksekkäässä vientiliiketoiminnan päätöksentekoprosessissa on lähtökohtaisesti neljä tunnettua kokonaisuutta:
                                </p>
                                <p>
                                    1. Vientiliiketoiminnan suunnittelu
                                    2. Markkinoille menon suunnittelu
                                    3. Markkinoille menon toteutus
                                    4. Liiketoiminnan laajentaminen olemassa olevilla maantieteellisillä alueilla.
                                </p>
                                <p>
                                    GENII Solutions Oy:n ainutlaatuinen tekoälysovellus antaa vastaukset vientipäätösten kysymyksiin lähes reaaliajassa!
                                    Käyttämällä tekoälyn kykyä analysoida suuria tietomääriä samanaikaisesti, vientiprojektien toteuttaminen on halvempaa ja nopeampaa verrattuna asiantuntija ja/tai konsulttitoimeksiantoihin, jotka Suomessa ovat yleisiä, mutta usein valitettavan hitaita ja ei-skaalattavia palveluita tarvittavan tiedon saamiseksi päätöksentekoa varten.
                                </p>
                                <p>
                                    Tekoälyavusteinen vientiliiketoiminnan päätöksentekotyökalu parantaa yritysten vientiliiketoiminnan ja kasvun edellytyksiä dramaattisesti nopeuttamalla tiedon analysointia ja tarjoamalla selkokielisiä toimenpidesuosituksia yrityksen ylimmälle johdolle perustuen ”valmiiksi strukturoituun” tietoon.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>
            </div>
            <div className='section3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='heading'>
                                <h2>
                                    Kuinka GENII Solutions Oy:n tekoälysovellus toimii käytännössä
                                </h2>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inner-content'>
                                <p>
                                    1. Yrityksen avainhenkilö(t) täyttävät Vientivalmiusanalyysin verkossa. Täyttäminen ja analysointi kestää maksimissaan tunnin. Analyysin lopputuloksena teillä on tiedossa oman yrityksenne vahvuudet ja mahdolliset kehittämiskohteet, joita ylläpitämällä ja kehittämällä kukin yksilöllinen yritys voi myös tulevaisuudessa varmistaa menestymistään.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inner-content'>
                                <p>
                                    2. Yrityksen avainhenkilö(t) päättävät, minkä tuotteiden ja palveluiden vientiä/liiketoimintaa halutaan tutkia/kehittää missäkin maassa ja millä tavalla-
                                    Yrityksethän ovat lähtökohtaisesti eri liiketoiminta-alueilta ja samanaikaisesti eri vaiheissa eri markkinoilla, minkä johdosta palvelu mukautuu kunkin yrityksen tilanteeseen.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inner-content'>
                                <p>
                                    3. Yrityksen avainhenkilö(t) ohjataan tekemään kysymyksiä ja tarkennettuja kysymyksiä, jonka jälkeen GENII Solutions Oy - tekoäly ”louhii” suuresta tietomäärästä kysymysten perusteella soveltuvat vaihtoehdot, kumppanit, tietolähteet tms. riippuen yksilöllisestä viennin tavoitteesta ja vaiheesta. Vastaukset tarjoillaan yritysjohdolle selkokielisenä päätöksentekoa varten lähes samanaikaisesti.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inner-content'>
                                <p>
                                    4. Yrityksen avainhenkilö(t) tekevät päätökset ja etenevät vientiliiketoiminnoissaan valitsemallaan tavalla, joka sopii oman yrityksen tilanteeseen ja resursseihin parhaiten.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section4'>
                <div className='container'>
                    <div className='row gy-4'>
                        <div className='col-md-6'>
                            <div className='content'>
                                <p>
                                    Kukin yritys voi hyödyntää GENII Solutions Oy:n tekoäly palvelua riippumatta siitä, missä vaiheessa ja millä maantieteellisellä alueella liiketoiminta on, tai missä sen halutaan olevan.
                                </p>
                                <p>
                                    Autamme myös erillisellä palvelulla rahoituksen hakemisessa sekä suomalaisten/ulkomaalaisten viranomaisten ja/tai sijoittajien kanssa.
                                </p>
                                <div className='button'>
                                    <button>Katso lisää</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='img-box'>
                                <img src={cc1} alt="">
                                </img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='heading'>
                                <h2>
                                    Tekoäly auttaa yrityksiä kasvamaan nopeammin kuin mikään muu innovaatio historiassa
                                </h2>
                            </div>
                            <div className='content'>
                                <p>
                                    Analysoi valtavia määriä dataa ennennäkemättömällä nopeudella ja paljastaa rakenteita ja trendejä jonka pohjalta yritykset voivat tehdä dataan perustuvia päätöksiä nopeasti ja tarkasti. Dataa voi halutessaan ohjata käsittelemään yksityiskohtaista tietoa, markkinoista, kilpailutilanteesta, kumppanivalinnoista, toimijoista markkinoilla, markkinapenetraation malleista (agentit, oma yritys, myyntikonttori), eli juuri siitä, mitä yrityksen avainhenkilö(t) yleensä joutuvat miettimään ennen investointeja vientiliiketoiminnan ja/tai liiketoiminnan kehittämiseksi.
                                </p>
                                <p>
                                    Käyttämällä GENII Solutions Oy:n tekoälyä voit saada tietoa mistä tahansa maasta haluamallasi tavalla ennen omaa päätöksentekoprosessia
                                </p>
                                <p>
                                    Nettisivu: <a href="https://www.genii-solutions.com">https://www.genii-solutions.com</a>
                                </p>
                             </div>
                            <div className='heading'>
                                <h2>
                                    GENII Solutions Ltd Oy:n tausta
                                </h2>
                            </div>
                            <div className='content'>
                                <p>
                                    Meillä on vuosikymmenien kokemus kansainvälisestä kaupasta, liiketoiminnan kehittämisestä sekä tietotekniikan hyödyntämisestä päätöksenteossa. Haluamme kehittää palveluita, jotka auttavat mahdollisimman monia yrityksiä kasvamaan sekä Suomessa että ulkomailla.
                                </p>
                                <p>
                                    Olemme tutkineet jo vuosia tekoälyn käyttöä suurten tietomäärien käsittelyssä erinomaisilla tuloksilla.
                                </p>
                                <p>
                                    Mikäli olet miettimässä ja/tai laajentamassa vientiliiketoimintaasi, suosittelemme ottamaan meihin välittömästi yhteyttä, niin kerromme lisää, miten voit säästää aikaa, rahaa ja resursseja GENII Solutions Oy:n tekoälyn avulla.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>

                        </div>
                    </div>
                </div>
            </div>
            <div className='section6'>
                <HiddenContact />
            </div>
        </div>
    )
}

export default HiddenPage
